import React, { useEffect } from 'react';
import styled from 'styled-components';
import 'plyr/dist/plyr.css';
import { SECONDARY_BG_COLOR } from '../constants';

const VideoWrapper = styled.div`
    height: 84vh;
    @media (max-width: 768px) {
        height: 60vh;
    }

    .plyr {
        height: 74vh;
        @media (max-width: 768px) {
            height: 60vh;
        }

        video {
            height: 100%;
            object-fit: cover;
        }
    }

    .plyr__video-wrapper {
        height: 100%;
    }

    .plyr__poster {
        height: 100%;
        background-size: cover;
        background-color: transparent;
    }

    .plyr__tab-focus {
        box-shadow: none;
        background: ${SECONDARY_BG_COLOR} !important;
    }

    .plyr__control--overlaid {
        border-radius: 0;
        background: ${SECONDARY_BG_COLOR};
    }

    .plyr__control:hover {
        background: ${SECONDARY_BG_COLOR} !important;
    }

    .plyr__controls__item:first-child {
        background: none;
    }

    .plyr__controls__item:first-child:hover {
        background: none;
    }

    .plyr--full-ui input[type='range'] {
        color: ${SECONDARY_BG_COLOR};
    }
`;

export const VideoPlayer = props => {
    useEffect(() => {
        let isMobile = false;
        if (typeof document !== 'undefined') {
            isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
            const plyr = require('plyr');
            const player = new plyr('#player');

            player.poster = props.poster;

            player.on('playing', () => {
                if (!player.fullscreen.active && isMobile) {
                    player.fullscreen.enter();
                }
            });

            player.on('pause', () => {
                if (player.fullscreen.active && isMobile) {
                    player.stop();
                    player.fullscreen.exit();
                }
            });

            player.clickToPlay = true;
        }
    });

    return (
        <VideoWrapper>
            <video id="player" playsinline>
                <source src={props.video} type="video/mp4" />
            </video>
        </VideoWrapper>
    );
};
