import Layout from '../components/layout/layout';
import React from 'react';
import styled from 'styled-components';
import { TextBlock } from '../components/shared/text-block';
import { PRIMARY_BG_COLOR, PRIMARY_TEXT_COLOR } from '../constants';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';

import 'plyr/dist/plyr.css';
import { VideoPlayer } from '../components/video-player';

// TODO: refactor after merge with case study details
const video = '/videos/careers-video.mp4';
const poster = '/images/careers/eq-vreckav2-min.jpg';

const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    display: block;

    &:not(:first-of-type) {
        padding-top: 50px;
    }
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);

    return (
        <Layout showShadow={true} setCanScroll={setCanScroll} canScroll={canScroll} bgColor={PRIMARY_BG_COLOR}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>equaleyes - Careers</title>
            </Helmet>
            <Wrapper color={PRIMARY_BG_COLOR}>
                <VideoPlayer video={video} poster={poster} />
                <Container>
                    <TextBlock title={'Why Equaleyes?'} txtcolor={PRIMARY_TEXT_COLOR}>
                        <h2>Help us become a tech giant in Europe.</h2>
                        <p>
                            We’re always on the lookout for outstanding talent to join our team both in Ljubljana and Maribor. Our company
                            culture is built on teamwork, dedication and a constant willingness to learn and grow.
                        </p>
                        <p>If our values resonate with you, make sure to get in touch!</p>
                    </TextBlock>
                </Container>
            </Wrapper>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <TextBlock title={'Open positions'} txtcolor={PRIMARY_BG_COLOR}>
                        <h1>Maribor & Ljubljana</h1>
                        <p>
                            While we’re not actively hiring at the moment, we are always open to student internships. If you’re passionate
                            about technology and eager to gain experience in a fast-paced environment, we encourage you to reach out.
                        </p>
                        <ul className="spaced">
                            {/*<li>*/}
                            {/*    <Link to={'/careers/maribor/web-developer/?language=eng'}>Web developer</Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to={'/careers/maribor/mobile-dev/?language=eng'}>Mobile developer</Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to={'/careers/maribor/qa-tester/?language=eng'}>QA Tester</Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to={'/careers/maribor/project-manager/?language=eng'}>Project manager</Link>*/}
                            {/*</li>*/}
                            {/* Uncomment to show job application. Also remove the useeffect redirect from UI/UX designer page */}
                            {/* <li>
                                <Link to={'/careers/maribor/ui-ux-designer/?language=eng'}>UI/UX Designer</Link>
                            </li> */}
                        </ul>
                    </TextBlock>
                </Container>
            </Wrapper>
        </Layout>
    );
};
